import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import NotFound404 from "../components/NotFound404";
import config from "../../data/SiteConfig";

class NotFoundPage extends Component {
  render() {
    return (
      <Layout>
        <div className="about-container">
          <Helmet title={`About | ${config.siteTitle}`} />
          <NotFound404 />
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
